import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { SecondaryHero } from '@latitude/hero';
import { Heading2 } from '@latitude/heading';
import { ALIGN } from '@latitude/core/utils/constants';
import { StickyNavigation } from '@latitude/sticky-navigation';
import Layout from '../components/layout';
import Section from '../components/Section/Section';
import PageData from '../data/pages/privacy/index.json';
import '../../sass/_table.scss';

const Privacy = ({ location, data }) => {
  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Helmet>
          <title>{PageData.metaTitle} </title>
          <meta name="description" content={PageData.metaDesc} />
          <link
            href="https://www.latitudefinancial.com.au/privacy/"
            rel="canonical"
          />
        </Helmet>

        <SecondaryHero heading="Privacy &amp; Credit Reporting policy" />

        <div className="d-none d-lg-block">
          <StickyNavigation
            items={PageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
          />
        </div>

        <Section id="statement" className="bg-lightest">
          <Heading2 align={ALIGN.CENTER}>
            Statement of Notifiable Matters
          </Heading2>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {ReactHTMLParser(data.allMarkdownRemark.edges[0].node.html)}
            </div>
          </div>
        </Section>
        <Section id="privacy-and-credit">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {ReactHTMLParser(data.allMarkdownRemark.edges[1].node.html)}
            </div>
          </div>
        </Section>
      </main>
    </Layout>
  );
};

export default Privacy;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/data/pages/privacy/*.md" } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
          }
        }
      }
    }
  }
`;
